import { render, staticRenderFns } from "./TwoOneColumnsContent.vue?vue&type=template&id=35732934&"
import script from "./TwoOneColumnsContent.vue?vue&type=script&lang=js&"
export * from "./TwoOneColumnsContent.vue?vue&type=script&lang=js&"
import style0 from "./TwoOneColumnsContent.vue?vue&type=style&index=0&id=35732934&prod&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CTAButton: require('/codebuild/output/src3157332276/src/eddys/components/global/CTAButton.vue').default,TitleRow: require('/codebuild/output/src3157332276/src/eddys/components/TitleRow.vue').default,ComponentBlockSingleColumnContent: require('/codebuild/output/src3157332276/src/eddys/components/component/block/SingleColumnContent.vue').default})
