
export default {
  name: "GalleryModal",
  props: {
    images: Array,
    imagesMobile: Array,
    galleryType: String,
  },
  computed: {
    isMobile() {
      return this.$device.isMobile || this.windowWidth < 768;
    },
    showGalleryModal() {
      return this.$store.state.galleryModal.visible
    },
    galleryTypeToShow() {
      return this.$store.state.galleryModal.type
    },
    slides() {
      return this.isMobile ? this.imagesMobile : this.images
    },
  },
  data() {
    return {
      toggleMobileGallery: false,
      tlMenuOverlay: null,
      windowWidth: 0,
    }
  },
  mounted() {
    this.updateWindowWidth();
    window.addEventListener('resize', () => {
      this.updateWindowWidth()
      this.updateGalleryOverlayPosition()
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', () => {
      this.updateWindowWidth()
      this.updateGalleryOverlayPosition()
    });
  },
  watch: {
    showGalleryModal(newValue) {
      if (newValue && this.galleryType === this.galleryTypeToShow) {
        this.menuOverlayAnimation();
        this.triggerMobileGalleryAnimation();
      }
    },
  },
  methods: {
    triggerCloseModal() {
      this.triggerMobileGalleryAnimation();
    },
    triggerMobileGalleryAnimation() {
      this.toggleMobileGallery = !this.toggleMobileGallery;
      if (this.toggleMobileGallery) {
        document.body.classList.add('is-nav-open');
        this.tlMenuOverlay.play();
      } else {
        this.tlMenuOverlay.reverse().then(() => {
          this.$store.commit('setGalleryModal', false)
        });
        document.body.classList.remove('is-nav-open');
      }
    },
    menuOverlayAnimation() {
      this.tlMenuOverlay = this.$gsap.timeline({paused: true});

      this.tlMenuOverlay.to(this.$refs['galleryOverlay'], {
        x: "0",
        duration: 0.5,
        ease: "power3.inOut",
      });
    },
    updateGalleryOverlayPosition() {
      if (this.showGalleryModal && this.galleryType === this.galleryTypeToShow) {
        // If the mobile gallery is open, set x to 0
        this.$gsap.to(this.$refs['galleryOverlay'], {x: 0});
      } else {
        // If the mobile gallery is closed, set x to translate it off-screen
        this.$gsap.to(this.$refs['galleryOverlay'], {x: '100%'});
      }
    },
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    }
  }
};
