
import imgPathsMixin from "@/mixins/imageMeta";

export default {
  name: "CateringAndLocations",
  mixins: [imgPathsMixin],
  props: {
    catering: Object,
    cateringList: Array,
    locations: Object,
    locationsList: Array,
  },
  computed: {
    imageAttributes() {
      return this.getAttribute(this.catering?.image?.data, 'attributes');
    },
  },
  mounted() {
    if (this.$device.isMobile) {
      this.initAnimationSequence();
    }
  },
  methods: {
    initAnimationSequence() {
      this.createScrollTrigger(this.$refs.title1, this.$refs.container1);
    },
    createScrollTrigger(title, container) {
      if (title && container) {
        // Retrieve the height of the title element
        const titleHeight = title.clientHeight;
        const endValue = `bottom-=${titleHeight}px 68px`;

        this.$ScrollTrigger.create({
          trigger: container,
          scrub: 0.5,
          start: "top 72px",
          end: endValue,
          pin: title,
          scroller: window.matchMedia("(max-width: 768px)").matches ? null : "[data-scroll-container]",
          anticipatePin: 1,
          // markers: true,
        });
      }
    }
  }
}
