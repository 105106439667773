
export default {
  name: "SingleColumnContent",
  props: {
    data: Object,
    options: Array,
    selectedSlug: String,
    type: String,
  },
}
