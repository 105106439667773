
import LocationIcon from "~/static/images/eddy_location_icon.svg?inline";
import MailIcon from "static/icons/mail.svg?inline";
import InstagramIcon from "static/icons/instagram.svg?inline";
import LinkedInIcon from "static/icons/linkedin.svg?inline";
import MP from "static/icons/mp.svg?inline";
import ONV from "static/icons/onvocado.svg?inline";

export default {
  name: "Footer",
  components: {LocationIcon, MailIcon, InstagramIcon, LinkedInIcon, MP, ONV},
  props: {
    showContacts: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    year() {
      const now = new Date()
      const yyyy = now.getFullYear()
      return yyyy
    }
  },
}
