import { render, staticRenderFns } from "./GalleryModal.vue?vue&type=template&id=3f018c60&"
import script from "./GalleryModal.vue?vue&type=script&lang=js&"
export * from "./GalleryModal.vue?vue&type=script&lang=js&"
import style0 from "./GalleryModal.vue?vue&type=style&index=0&id=3f018c60&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Burger: require('/codebuild/output/src3157332276/src/eddys/components/global/Burger.vue').default,SwiperCarrousel: require('/codebuild/output/src3157332276/src/eddys/components/SwiperCarrousel.vue').default})
