
import imgPathsMixin from "@/mixins/imageMeta";

export default {
  name: "AboutAndEmployment",
  props: {
    about: Object,
    employment: Object,
    employmentList: Array,
  },
  mixins: [imgPathsMixin],
  data() {
    return {
      windowWidth: 0, // Initialize window width
    };
  },
  mounted() {
    this.updateWindowWidth();
    window.addEventListener('resize', this.updateWindowWidth);

    if (this.$device.isMobile) {
      this.initAnimationSequence();
    }
  },
  computed: {
    isMobile() {
      return this.$device.isMobile || this.windowWidth < 640;
    },
    imageAttributes() {
      return this.getAttribute(this.employment?.image?.data, 'attributes');
    },
    aboutImageAttributes() {
      return this.getAttribute(this.about?.image?.data, 'attributes');
    },
    aboutImageMobileAttributes() {
      return this.getAttribute(this.about?.imageMobile?.data, 'attributes');
    },
  },
  methods: {
    initAnimationSequence() {
      this.createScrollTrigger(this.$refs.title1, this.$refs.container1);
      this.createScrollTrigger(this.$refs.title2, this.$refs.container2);
    },
    createScrollTrigger(title, container) {
      if (title && container) {
        // Retrieve the height of the title element
        const titleHeight = title.clientHeight;
        const endValue = `bottom-=${titleHeight}px 64px`;

        this.$ScrollTrigger.create({
          trigger: container,
          scrub: 0.5,
          start: "top 68px",
          end: endValue,
          pin: title,
          scroller: window.matchMedia("(max-width: 768px)").matches ? null : "[data-scroll-container]",
          anticipatePin: 1,
          // markers: true,
        });
      }
    },
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    }
  }
}
