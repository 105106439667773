
export default {
  name: "TwoOneColumnsContent",
  props: {
    data: Object,
  },
  data() {
    return {
      showMobileGalleryBtn: false,
      observer: null,
      sectionSubtitleIndex: 0,
    };
  },
  mounted() {
    if (this.$device.isMobile) {
      this.initAnimationSequence();
      this.initIntersectionObserver();
    }
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    triggerGallery() {
      this.$store.commit('setGalleryModal', {visible: true, type: 'food'})
    },
    initAnimationSequence() {
      const title = this.$refs['title'];
      const container = this.$refs['container'];
      // Retrieve the height of the title element
      const titleHeight = title.clientHeight;
      const endValue = `bottom-=${titleHeight}px 68px`;

      this.$ScrollTrigger.create({
        trigger: container,
        scrub: 0.5,
        start: "top 72px",
        end: endValue,
        pin: title,
        scroller: window.matchMedia("(max-width: 768px)").matches ? null : "[data-scroll-container]",
        anticipatePin: 1,
        // markers: true,
        onEnter: () => {
          // console.log("onEnter");
          this.showMobileGalleryBtn = true;
        },
        onEnterBack: () => {
          // console.log("onEnterBack");
          this.showMobileGalleryBtn = true;
        },
        onLeave: () => {
          // console.log("onLeave");
          this.showMobileGalleryBtn = false;
        },
        onLeaveBack: () => {
          // console.log("onLeaveBack");
          this.showMobileGalleryBtn = false;
        },
      });
    },
    initIntersectionObserver() {
      const options = {
        root: null, // Use the viewport as root
        rootMargin: '0px',
        threshold: 0.1 // Adjust as needed
      };

      this.observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // Element has come into view
            this.handleElementInView(entry.target);
          } else {
            // Element has gone out of view
            this.handleElementOutOfView(entry.target);
          }
        });
      }, options);

      // Assuming you want to observe each content column
      this.$refs.contentColumns.forEach(column => {
        this.observer.observe(column.$el)
      });
    },
    handleElementInView(element) {
      // Logic for when the element comes into view
      // console.log('Element in view:', element);
      const dataIndex = element.dataset.index;
      this.sectionSubtitleIndex = dataIndex;
      // console.log('Element in view with data-index:', dataIndex);
    },
    handleElementOutOfView(element) {
      // console.log('Element out of view:', element);
      const dataIndex = element.dataset.index;
      if (dataIndex === this.sectionSubtitleIndex && dataIndex > 0) {
        this.sectionSubtitleIndex = dataIndex - 1;
      }
      // console.log('Element out of view with data-index:', dataIndex);
    },
  },
}
