
import homeQuery from "@/apollo/queries/pages/home.gql";
import locationsQuery from "@/apollo/queries/pages/locations.gql";
import employmentQuery from "@/apollo/queries/pages/employment.gql";
import locomotiveScrollMixin from '@/mixins/locomotiveScrollMixin';
import previewModeMixin from '@/mixins/previewModeMixin';

export default {
  name: 'index',
  colorMode: 'light',
  mixins: [locomotiveScrollMixin, previewModeMixin],
  data() {
    return {
      home: null,
      locations: null,
      employment: null,
      loadModalGalleries: false,
    };
  },
  async asyncData({app, store}) {
    const previewMode = store.state.previewMode;

    if (previewMode) {
      await app.apolloProvider.defaultClient.resetStore();
    }

    const responseHome = await app.apolloProvider.defaultClient.query({
      query: homeQuery,
      variables: {
        locale: app.i18n.locale,
      },
    }).catch((err) => {
      console.log(JSON.stringify(err, null, 2));
    });

    const home = responseHome.data?.home;

    const responseLocations = await app.apolloProvider.defaultClient.query({
      query: locationsQuery,
      variables: {
        locale: app.i18n.locale,
      },
    }).catch((err) => {
      console.log(JSON.stringify(err, null, 2));
    })

    const locations = responseLocations.data?.locations;

    const responseEmployment = await app.apolloProvider.defaultClient.query({
      query: employmentQuery,
      variables: {
        locale: app.i18n.locale,
      },
    }).catch((err) => {
      console.log(JSON.stringify(err, null, 2));
    })

    const employment = responseEmployment.data?.employments;

    return {
      home,
      locations,
      employment,
      previewMode,
    };
  },
  created() {
    this.$nextTick(() => {
      this.$colorMode.preference = 'light';
    });
  },
  mounted() {
    this.loadModalGalleries = true;
    this.scrollToHash();
  },
  methods: {
    scrollToHash() {
      this.$nextTick(() => {
        const hash = this.$route.hash;
        if (hash) {
          this.locoScroll.scrollTo(hash, {duration: 0});
        }
      });
    },
    triggerScrollTop() {
      this.locoScroll.scrollTo(0, {duration: 500});
    },
  },
}
